<template>
  <img alt="Vue logo" src="./assets/fantasy_football.png">
  <HelloWorld msg="Welcome to The Fantasy Football Board"/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import * as firebase from 'firebase/app'
export default {
  name: 'App',
  components: {
    HelloWorld
  },
  metaInfo: {
    changed(metaInfo) {
      firebase.analytics().setCurrentScreen(metaInfo.title);
      firebase.analytics().logEvent("page_view");
      firebase.analytics().logEvent("screen_view", {
        app_name: "my-website",
        screen_name: metaInfo.title,
        app_version: "1.0"
      });
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
